import React from "react";
import { SocialLinks } from "../header/header";

export const SocialSection = () => {
    return (
        <section className=" block md:hidden w-full pb-20 z-10">
            <div
                className={" max-w-[1230px] mx-auto z-20 px-5 flex flex-row justify-center items-center"} 
            >
                {SocialLinks.map((link, index) => {
                    return (
                        <div className="mx-5" key={"key_" + index}>
                            <a
                                href={link.link}
                                target="_blank"
                            >
                                {link.element}
                            </a>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};
