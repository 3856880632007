import React, { FC, ReactNode, useMemo } from "react";
import Header from "./components/header/header";
import StickSection from "./components/stick-section/stick-section";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Toaster } from "react-hot-toast";
import { BrowserView } from "react-device-detect";
import ParticulesWave from "./components/particules-wave/particules-wave";
import Home from "./pages/Home";

const App: FC = () => {
    return (
        <div className="App">
            <Toaster position="bottom-center" />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
