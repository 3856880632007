import axios from "axios";
import { MIGRATION_API } from "../constants";

export enum VestingCategory {
    Other = 0,
    Seed = 1,
    Ps1 = 2,
    Ps2 = 3,
    Team = 4,
    Investor = 5,
}

const Api = {
    signupEmail: async (email: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                let payload: any = {
                    email,
                };
                const { data } = await axios.post(
                    `${MIGRATION_API}/mail/register`,
                    payload
                );

                resolve(data);
            } catch (ex: any) {
                console.log({ ex });
                reject(ex);
            }
        });
    },
};

export default Api;
