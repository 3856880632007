


export const shortAddress = (addr?: string, maxLen = 12) => {
    if (!addr) {
        return '';
    }
    if (addr?.length < maxLen) {
        return addr;
    }

    return addr.substring(0, 6) + '...' + addr.substring(addr.length - 5, addr.length);
};

export const verifyEmail = (value: string) => {
    if (
        value.match(
            /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
        )
    ) {
        return true;
    } else {
        return false;
    }
};

export const copyClipboard = (val?: string) => {

    if (!val) {
        return ;
    }
    navigator.clipboard.writeText(val);
};
