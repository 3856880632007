import React from "react";
import Header from "../components/header/header";
import StickSection from "../components/stick-section/stick-section";
import { BrowserView } from "react-device-detect";
import ParticulesWave from "../components/particules-wave/particules-wave";
import TopBanner from "../components/header/top-banner/top-banner";
import { SocialSection } from "../components/social-section/social-section";
import clsx from "clsx";

const Home: React.FC = () => {
    return (
        <div className="relative ">

            <div className="absolute top-0 left-0 right-0 bottom-0 z-[0]">
                <img
                    src={"/assets/svgs/lines.svg"}
                    className="  fixed top-[-50%] md:top-0 h-[150vh] max-w-[250%] md:h-[100vh] 2xl:h-[130vh] right-[-160%] sm:right-[-100%] md:right-[-50%] lg:right-0 min-w-[45vw] object-cover"
                    draggable={false}
                />
                <div className="bg-hider z-[0]"></div>
            </div>
            <Header />

            <div className=" relative z-10 min-h-[100vh] sm:min-h-[calc(110vh)] md:min-h-[calc(100vh+60px)]">
                <StickSection />
                <SocialSection />    
            </div>
            
            {/* <BrowserView>
                <div className=" fixed bottom-0 left-0 right-0 z-0 ">
                    <ParticulesWave />
                </div>
            </BrowserView> */}
            <div className=" relative w-full py-10 md:py-4  pr-5 z-[30] flex flex-row items-center">
                <div className=" absolute top-[2px] left-0 bottom-0 bg-[#14C000] w-[50vw] rounded-se-[20px] "></div>
                <div className=" absolute top-0 right-0 bottom-0 bg-[#14C000] w-[50vw] rounded-ss-[20px] "></div>
                <div className=" z-[100] mx-auto">
                    <p
                        className={clsx(
                            "text-[#151B30] text-center mx-3 mt-1 font-[700] text-[12px] leading-[13px] ",
                            "lg:text-[19px] lg:leading-[21px]"
                        )}
                    >
                        Sign up today to secure your spot in the exclusive
                        testing group and be in with a chance to claim your
                        share of a SERSH Airdrop!
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Home;
