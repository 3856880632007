import React from "react";
import "./signup-btn.scss";
import clsx from "clsx";
import { BiLoaderAlt } from "react-icons/bi";

export type SignupBtnProps = {
    onClick?: () => void;
    title?: string;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
};

const SignupBtn: React.FC<SignupBtnProps> = ({
    className,
    title,
    loading,
    onClick,
    disabled,
}) => {
    const onPress = () => {};

    const handleClick = () => {
        if (disabled) return;

        onClick?.();
    };

    return (
        <div
            className={clsx(
                "start-staking-wrapper ",
                className ? className : "",
                " color",
                "flex flex-row items-center justify-center"
            )}
            onClick={onClick ? handleClick : onPress}
        >
            {loading && <BiLoaderAlt className="animate-spin text-white w-[30px] h-[30px]" />}

            <p className={clsx( loading ? ' opacity-10' : 'opacity-100')}>{title}</p>
        </div>
    );
};

export default SignupBtn;
