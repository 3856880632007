import React, { useState } from "react";
import "./stick-section.scss";
import { isMobile, BrowserView, MobileView } from "react-device-detect";

import SignupBtn from "../signup-btn/signup-btn";
import clsx from "clsx";
import { verifyEmail } from "../../utils";
import toast, { Toaster } from "react-hot-toast";
import Api from "../../services/api";

export type StickSectionProps = {
    onViewInit?: (ref: HTMLElement) => void;
};

const StickSection: React.FC<StickSectionProps> = ({ onViewInit }) => {
    const stickRef = React.useRef<HTMLElement | null>(null);

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const onSignup = async () => {
        if (!verifyEmail(email)) {
            toast.error("Email is invalid. please enter correct email.");
            return;
        }

        try {
            setLoading(true);
            const res = await Api.signupEmail(email);
            toast.success("Successfully registered.");
        } catch (ex: any) {
            console.log(ex);

            toast.error("Failed to signup. " + ex.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section
            className={`stick-section relative pb-[50px]`}
            id="stick-section"
            ref={stickRef}
        >
            <div
                className={
                    " max-w-[1230px] mx-auto z-20 px-5 "
                }
                id="stick-section-1"
            >
                <div className={`container  pt-0 md:pt-[50px]`}>
                    <div
                        className={` flex  flex-col md:flex-row justify-center `}
                    >
                        <div className=" flex-1 px-4">
                            <div className="text-center relative lg:text-left stick-text-container">
                                <div
                                    className={`head-large text-white green-thin-text-bold `}
                                >
                                    REDEFINING
                                </div>
                                <div
                                    className={`head-large text-[#14C000] green-thin-text-bold`}
                                >
                                    PRIVACY
                                </div>

                                <div
                                    className={clsx(
                                        "head-desc text-center md:text-left mb-10 mt-5"
                                    )}
                                >
                                    <p className="my-5">
                                        Serenity Shield's website is currently
                                        undergoing a refresh. Stay tuned as we
                                        prepare to launch the next generation
                                        privacy solution.
                                    </p>
                                    <p className="my-5">
                                        Get ready to unlock the full potential
                                        of our multi-chain, StrongBox DApp - the
                                        privacy hub solution that safeguards
                                        your digital world like never before.
                                    </p>
                                </div>
                                <div className=" flex flex-col items-stretch max-w-[270px] md:max-w-[100vw]  md:flex-row md:justify-between md:items-center">
                                    <input
                                        type="email"
                                        className="md:flex-1 relative text-white md:mr-4 z-20 h-[50px] text-center my-2 bg-transparent border border-solid border-white rounded-full placeholder:text-white "
                                        placeholder="EMAIL ADDRESS"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.currentTarget.value);
                                        }}
                                    />
                                    {/* <div id="signup-main" className="flex-1"> */}
                                    <SignupBtn
                                        title="SIGN UP!"
                                        className="md:flex-1 z-10 my-2 h-[50px] w-full"
                                        onClick={onSignup}
                                        loading={loading}
                                        disabled={loading}
                                    />
                                    {/* </div> */}
                                </div>
                                {/* <div className="md:mx-5 lg:mx-3">
                                    <p className=" font-thin text-sm text-center md:text-left  max-w-[407px] mt-5 pl-2 mx-auto md:mx-0">
                                        Sign Up for our exclusive StrongBox®️
                                        DApp testing group, provide valuable
                                        feedback and be rewarded for your work!
                                        Limited spots available.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className={` flex-1 z-10 hidden md:flex`}>
                            <div className=" mt-[-60px] cursor-pointer md:translate-x-[9%] relative video-player-container">
                                <img
                                    src="/assets/imgs/tel.png"
                                    className=" object-contain scale-90  "
                                />
                               
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="mt-[150px] text-[14px] text-white font-[biennalelight] font-[100] text-justify ">
                    <span className="font-bold text-[#15c001] ">DISCLAIMER:</span> Privacy: We define privacy as a fundamental
                    human right, not a mask for secrecy. Serenity Shield
                    provides confidentiality and secure data handling services,
                    with access strictly for verified entities only.We intend
                    our product for the sole purpose of storing confidential
                    information and categorically reject secrecy as a necessary
                    component of privacy. Our product is specifically designed
                    and intended for storing confidential information while
                    ensuring transparency according to all applicable and
                    prevailing laws and regulations.
                </div>
            </div>
        </section>
    );
};

export default StickSection;
