import React, { useState } from "react";
import "./header.scss";
import clsx from "clsx";

import TopBanner from "./top-banner/top-banner";
import SignupBtn from "../signup-btn/signup-btn";
import Drawer from "../drawer/drawer";
import BtnClose from "../btn-close/btn-close";
const whitepaper = require("../../assets/pdfs/serenityshield-white-paper-2023.pdf");
const termsPdf = require("../../assets/pdfs/terms_and_condition.pdf");

export const SocialLinks = [
    {
        link: "https://twitter.com/SerenityShield_",
        class: "link",
        element: <img src="/assets/svgs/icons/icon-twitter.svg" />,
    },
    {
        link: "https://discord.com/invite/serenityshield",
        class: "link ",
        element: <img src="/assets/svgs/icons/icon-discord.svg" />,
    },
    {
        link: "https://medium.com/serenity-shield",
        class: "link",
        element: <img src="/assets/svgs/icons/icon-medium.svg" />,
    },
    {
        link: "https://t.me/serenityshield",
        class: "link",
        element: <img src="/assets/svgs/icons/icon-telegram.svg" />,
    },
];

export default function Header() {
    const [openDrawer, setOpenDrawer] = useState(false);

    const onClose = () => {
        setOpenDrawer(false);
    };
    const onMenuClick = () => {
        setOpenDrawer(!openDrawer);
    };

    return (
        <>
            <header className="desktop" id="header-section">
                {/* <TopBanner /> */}
                <div className="container main-container mx-auto px-5 ">
                    <div className="flex flex-row justify-between items-center mt-5">
                        <div className=" flex-[3] ">
                            <a href="https://serenityshield.io">
                                <img
                                    className="logo mt-4 img img-fluid scale-90"
                                    src={`/assets/svgs/logo-new.svg`}
                                    alt="Serenity Shield"
                                />
                            </a>
                        </div>
                        <div className=" flex-[9]">
                            <ul className="nav flex flex-row">
                                <li className="nav-item text-white flex">
                                    <a
                                        href={termsPdf}
                                        target="_blank"
                                        className=" font-bold px-2"
                                    >
                                        Terms & Conditions
                                    </a>
                                </li>
                                <li className="nav-item text-white flex">
                                    <a
                                        href={whitepaper}
                                        target="_blank"
                                        className=" font-bold px-2"
                                    >
                                        WhitePaper
                                    </a>
                                </li>
                                {/* <li className="nav-item text-[#40f1a7] flex">
                                    <a
                                        href={termsPdf}
                                        target="_blank"
                                        className=" font-bold px-2"
                                    >
                                        Terms & Conditions
                                    </a>
                                </li> */}
                                {SocialLinks.map((link, index) => {
                                    return (
                                        <li
                                            key={"li_" + index}
                                            className="nav-item flex"
                                        >
                                            <a
                                                href={link.link}
                                                target="_blank"
                                                className={
                                                    "nav-link " + link.class
                                                }
                                            >
                                                {link.element}
                                            </a>
                                        </li>
                                    );
                                })}
                                {/* <div className=" ml-6 ">
                                    <a href="#signup-main">
                                        <SignupBtn title="SIGN UP!" />
                                    </a>
                                </div> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            {/* <div className=" lg:hidden ">
                <TopBanner />
            </div> */}

            <header className=" locked px-4 sm:px-5 relative mobile ">
                <div className="flex flex-row md:px-5 py-3">
                    <div className="flex-1 flex items-center justify-start ">
                        <div className=" w-100">
                            <a href="https://serenityshield.io">
                                <img
                                    className="logo my-3 img img-fluid"
                                    src={`/assets/svgs/logo-new.svg`}
                                    alt="Serenity Shield"
                                />
                            </a>
                        </div>
                    </div>

                    <div className="flex-1 flex items-center justify-end">
                        <ul className="nav flex flex-row">
                            <li className="nav-item text-white">
                                <a
                                    href={whitepaper}
                                    target="_blank"
                                    className=" font-bold px-2"
                                >
                                    WhitePaper
                                </a>
                            </li>
                            {/* <li className="nav-item text-[#40f1a7] whitespace-nowrap">
                                <a
                                    href={termsPdf}
                                    target="_blank"
                                    className=" font-bold px-2 "
                                >
                                    Terms & Conditions
                                </a>
                            </li> */}
                            {SocialLinks.map((link, index) => {
                                return (
                                    <li
                                        key={"li_" + index}
                                        className="nav-item hidden md:flex"
                                    >
                                        <a
                                            href={link.link}
                                            target="_blank"
                                            className={"mx-1 nav-link"}
                                        >
                                            {link.element}
                                        </a>
                                    </li>
                                );
                            })}
                            {/* <div className=" ml-6 ">
                                <SignupBtn title="SIGN UP!" />
                            </div> */}
                        </ul>
                        {/* <div
                            className={
                                openDrawer ? "menu-icon-hide" : "menu-icon-show"
                            }
                        >
                            <img
                                className=" cursor-pointer "
                                src={"assets/svgs/menu.svg"}
                                alt="Serenity Shield"
                                onClick={onMenuClick}
                            />
                        </div> */}
                    </div>
                </div>
                {/* <Drawer openDrawer={openDrawer} onClose={onClose} /> */}
            </header>
        </>
    );
}
